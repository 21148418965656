import React from "react";
import {Link} from "./Link";
import {socials} from "../../config/consts";

type IconProps = {
  onClick?: (ev: any) => any;
}

export const FacebookLink = ({onClick}: IconProps) => (
  <Link
    target="_blank"
    rel="noopener"
    href={socials.fb}
    onClick={onClick}
    aria-label="facebook"
  >
    <i className={"fab fa-facebook"}/>
  </Link>);
export const InstagramLink = ({onClick}: IconProps) => (
  <Link
    target="_blank"
    rel="noopener"
    href={socials.instagram}
    onClick={onClick}
    aria-label="instagram"
  >
    <i className="fab fa-instagram"/>
  </Link>);
export const LinkedInLink = ({onClick}: IconProps) => (
  <Link
    target="_blank"
    rel="noopener"
    href={socials.linkedin}
    onClick={onClick}
    aria-label="linkedin"
  >
    <LinkedIn/>
  </Link>);

export const LinkedIn = () => (<i className="fab fa-linkedin"/>);

export const Users = () => <i className="far fa-users"/>;
export const History = () => <i className="far fa-history"/>;
export const Star = () => <i className="far fa-stars"/>;
export const Hotel = () => <i className="far fa-hotel"/>;
export const Arrow = () => <i className="fas fa-caret-down 1fx"/>;
