import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { strings } from 'consts/translations';
import {
  Arrow,
  FacebookLink,
  InstagramLink,
  LinkedInLink,
} from 'components/Icons';
import { Link } from 'components/Link';
import { Logo } from 'components/Logo';
import { Badge } from 'components/Badge/Badge';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { LanguageSwitcher } from 'components/LanguageSwitcher/LanguageSwitcher';
import styles from './Header.module.scss';

export const Header = ({
  hide = true,
  serviceLinksData,
  commonData,
  localized,
}: {
  hide?: boolean;
  serviceLinksData: any;
  commonData: any;
  localized: any[];
}) => {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [slide, setSlide] = useState('0px');
  const [open, toggleMenu] = useState(false);
  const router = useRouter();
  const handleScroll = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const currentScrollY = window.scrollY > 134 ? window.scrollY : 0;
    if (hide && currentScrollY > lastScrollY) {
      setSlide('-134px');
    } else {
      setSlide('0');
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    if (process.browser) {
      if (open) {
        document.body.style.setProperty('overflow-y', 'hidden');
      } else {
        document.body.style['overflow-y'] = 'auto';
      }
    }
    document.addEventListener('scroll', handleScroll);
    window.addEventListener('keyup', (KeyboardEvent) => {
      if (KeyboardEvent.key === 'Escape') {
        toggleMenu(false);
      }
    });
    return () => document.removeEventListener('scroll', handleScroll);
  });

  return (
    <>
      <Head>
        <meta name="theme-color" content={`${open ? '#0E1035' : '#0E1035'}`} />
      </Head>
      <nav
        className={`${styles.header} ${
          lastScrollY > 134 ? styles.sliding : ''
        }`}
        style={{
          transform: `translateY(${slide})`,
        }}
      >
        <div className={`${styles['header-bar']} grid`}>
          <Link
            href={`/${router.locale}/`}
            className={styles.logo}
            name="Unikoo logo"
          >
            <Logo />
          </Link>

          <div className={styles['main-nav']}>
            <Link href={`/${router.locale}/`} className={styles['nav-link']}>
              <h2>Home</h2>
            </Link>

            <Dropdown
              label={
                <Link
                  href={`/${router.locale}/services`}
                  className={styles['nav-link']}
                >
                  <h2>
                    Services
                    <Arrow />
                  </h2>
                </Link>
              }
              minWidth={true}
              entries={serviceLinksData.entries.map((service) => (
                <Link
                  key={service.id}
                  href={`/${router.locale}/${service.uri}`}
                  className={styles['sub-nav-link']}
                >
                  <h2>{service.title}</h2>
                </Link>
              ))}
            />

            {/* <Link
              href={`/${router.locale}/team`}
              className={styles['nav-link']}
            >
              <h2>Team</h2>
            </Link> */}

            <Link
              href={`/${router.locale}/career`}
              className={`${styles['nav-link']} ${styles['career-badge-wrapper']}`}
            >
              <Badge amount={commonData.jobOffers} />
              <h2>Career</h2>
            </Link>

            <Link
              href={`/${router.locale}/blog`}
              className={styles['nav-link']}
            >
              <h2>Blog</h2>
            </Link>

            <Link
              href={`/${router.locale}/about`}
              className={styles['nav-link']}
            >
              <h2>About</h2>
            </Link>

            <Link
              href={`/${router.locale}/contact`}
              className={styles['nav-link']}
            >
              <h2>Contact</h2>
            </Link>
          </div>

          <div
            className={styles['menu-button']}
            onClick={() => toggleMenu(!open)}
          >
            <h2>Menu</h2> <i className="fal fa-bars" />
          </div>
        </div>

        <div className={`${styles.mega} ${open ? styles.show : ''}`}>
          <div className={`grid ${styles['header-bar']}`}>
            <Link href={`/${router.locale}/`} className={styles.logo}>
              <Logo color="#ffffff" />
            </Link>

            <div
              className={styles['menu-button']}
              onClick={() => toggleMenu(!open)}
            >
              <i className="fal fa-times" />
            </div>
          </div>

          <nav className={`grid ${styles['mega-grid']}`}>
            <Link href={`/${router.locale}/`} onClick={() => toggleMenu(!open)}>
              <h2>Home</h2>
            </Link>

            <Link
              href={`/${router.locale}/services`}
              onClick={() => toggleMenu(!open)}
            >
              <h2>Services</h2>
            </Link>

            {serviceLinksData.entries.map((team) => (
              <Link
                key={team.id}
                className={styles['sub-nav-link']}
                href={`/${router.locale}/${team.uri}`}
                onClick={() => toggleMenu(!open)}
              >
                <h3>{team.title}</h3>
              </Link>
            ))}

            <Link
              href={`/${router.locale}/contact`}
              onClick={() => toggleMenu(!open)}
            >
              <h2>Contact</h2>
            </Link>

            <div className={styles['extra-links']}>
              <Link
                href={`/${router.locale}/about`}
                className="left"
                onClick={() => toggleMenu(!open)}
              >
                <h2>About</h2>
              </Link>

              <Link
                href={`/${router.locale}/team`}
                onClick={() => toggleMenu(!open)}
              >
                <h2>Team</h2>
              </Link>

              <Link
                href={`/${router.locale}/career`}
                onClick={() => toggleMenu(!open)}
              >
                <Badge amount={commonData.jobOffers} />
                <h2>Career</h2>
              </Link>

              <Link
                href={`/${router.locale}/blog`}
                onClick={() => toggleMenu(!open)}
              >
                <h2>Blog</h2>
              </Link>
              <Link href="https://unikoo.prezly.com/" target="_blank">
                <h2>{strings[router.locale].press}</h2>
              </Link>
            </div>

            <div className={styles.socials}>
              <FacebookLink onClick={() => toggleMenu(!open)} />
              <InstagramLink onClick={() => toggleMenu(!open)} />
              <LinkedInLink onClick={() => toggleMenu(!open)} />
            </div>

            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={commonData.common[0].termsAndConditionsPdf[0].url}
              className={styles['terms-header']}
            >
              {strings[router.locale].generalTerms}
            </Link>

            <Link href={`/${router.locale}/privacy`} className={styles.privacy}>
              Privacy Policy
            </Link>

            <LanguageSwitcher
              type={'big'}
              localized={localized}
              className={styles.languages}
            />
          </nav>
        </div>
      </nav>
    </>
  );
};
export default Header;
