import React from "react";
import styles from './Dropdown.module.scss'

export const Dropdown = ({label, entries, minWidth}) => {
  return <div className={styles.dropdown}>
    {label}
    <div>
      <div className={`${styles.dropdownContent} ${minWidth ? styles.minWidth : ''}`}>
        {entries}
      </div>
    </div>
  </div>;
}
