export const BASE_URL = `https://backend.unikoo.be`;
export const socials = {
  fb: 'https://www.facebook.com/unikoobelgium/',
  instagram: 'https://www.instagram.com/unikoobelgium/',
  linkedin: 'https://www.linkedin.com/company/unikoo/',
};

export const contactInfo = {
  phone: '+32 (0)3 298 06 71',
  email: 'hello@unikoo.be',
};
