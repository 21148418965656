export const strings = {
  en: {
    generalTerms: 'General Terms and Conditions',
    defaultJobOfferLocation: 'Antwerp',
    back: 'Go back',
    careerLocationFilter: 'Location',
    careerServiceFilter: 'Service',
    careerFilterAll: 'Show all',
    noCareers: 'No vacancies found with the given filters.',
    showAll: 'Show all',
    caseSlugPage: {
      seeMoreTitle: "Other cases we've done",
      seeMoreSubTitle: 'Warmed up ?',
    },
    press: 'Press'
  },
  nl: {
    generalTerms: 'Algemene voorwaarden',
    defaultJobOfferLocation: 'Antwerpen',
    back: 'Ga terug',
    careerLocationFilter: 'Locatie',
    careerServiceFilter: 'Service',
    careerFilterAll: 'Toon alle',
    noCareers: 'Geen vacatures gevonden op basis van de opgegeven filters.',
    showAll: 'Alles tonen',
    caseSlugPage: {
      seeMoreTitle: 'Wat wij nog meer gedaan hebben',
      seeMoreSubTitle: 'Opgewarmd ?',
    },
    press: 'Pers'
  },
};
