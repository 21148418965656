export const languages: { locale: string; label: string }[] = [
  { locale: 'nl', label: 'NL' },
  { locale: 'en', label: 'EN' },
];

export enum TeamName {
  DEV = 'development',
  DES = 'design',
  MAR = 'marcom',
  FIN = 'finadmin',
  REC = 'recruitment',
}

export const teamColors: { [teamname: string]: string } = {
  [TeamName.DEV]: '#15A391',
  [TeamName.MAR]: '#8C4792',
  [TeamName.REC]: '#ED7863',
  [TeamName.FIN]: '#5B9CCF',
  [TeamName.DES]: '#F7952A',
};

export const unikooDefaultColor = '#0E1035';
