import React, { HTMLProps } from 'react';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { Targets } from 'consts/motion.consts';

interface LinkProps extends HTMLProps<HTMLAnchorElement> {
  as?: string;
  href: string;
  'data-background-image'?: string;
  delay?: number;
}

export const Link = (props: LinkProps) => {
  const router = useRouter();

  let className = props.className || '';
  if (
    props.href &&
    (router.asPath?.toUpperCase() === props.href?.toUpperCase() ||
      (props.as && router.asPath?.toUpperCase() === props.as?.toUpperCase()))
  ) {
    className = `${className} selected`;
  }

  return (
    // <NextLink href={props.href} as={props.as}>
    <a
      {...props}
      className={className}
      data-background-image={props['data-background-image']}
    >
      {props.children}
    </a>
    // </NextLink>
  );
};

export const AnimatedLink = (props: LinkProps) => {
  const router = useRouter();

  let className = props.className || '';
  const fromSide = className.includes('previous') ? 'left' : 'right';
  if (
    router.asPath?.toUpperCase() === props.href?.toUpperCase() ||
    (props.as && router.asPath?.toUpperCase() === props.as?.toUpperCase())
  ) {
    className = `${className} selected`;
  }

  const animationState = {
    hidden: (side) => ({
      target: Targets.HIDDEN,
      opacity: 0,
      rotate: side === 'left' ? 90 : -90,
      scaleX: 2,
      x: side === 'left' ? -100 : 100,
    }),
    visible: (_, from) => ({
      target: Targets.VISIBLE,
      opacity: 1,
      scaleX: 1,
      x: 0,
      transition: {
        type: 'spring',
        duration: 1,
        bounce: 0.25,
        delay: from.target === Targets.HOVER ? 0 : props.delay || 2,
      },
    }),
    hover: (side) => ({
      target: Targets.HOVER,
      x: side === 'left' ? 14 : -14,
    }),
  };

  return (
    // <NextLink href={props.href} as={props.as}>
    <motion.a
      layout
      href={props.href}
      rel={props.rel}
      custom={fromSide}
      variants={animationState}
      initial={'hidden'}
      animate={'visible'}
      whileHover={'hover'}
      transition={{ type: 'spring', duration: 1, bounce: 0.25 }}
      className={className}
      data-background-image={props['data-background-image']}
    >
      {props.children}
    </motion.a>
    // </NextLink>
  );
};
