import Cookies from 'js-cookie';
import addYears from 'date-fns/addYears';
import startOfToday from 'date-fns/startOfToday';

export const getLanguage = (context) => {
  if (!context) return '1';
  switch (context.locale) {
    case 'en':
      return '2';
    default:
      return '1';
  }
};

export const isProduction = (): boolean => {
  return process.env && process.env.NEXT_PUBLIC_RUNTIME_ENV === 'production';
};

export const verifyCaptcha = async (token) =>
  await fetch('/api/recaptcha', {
    method: 'POST',
    headers: {
      Origin: 'localhost:3000',
    },
    body: JSON.stringify({
      token: token,
    }),
  }).then((res) => res.json());

export const setLocaleCookie = (locale) => {
  Cookies.set('NEXT_LOCALE', locale, {
    expires: addYears(startOfToday(), 5),
    path: '/',
  });
};
