import React from "react";

export const Logo = ({color = "#0E1035"}) => (
  <svg width="140px" height="34px" viewBox="0 0 140 34" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>EEB409F0-F2CD-4702-BFDC-49DC11312D4D</title>
    <desc>Created with sketchtool.</desc>
    <defs>
      <polygon id="path-1" points="0 0.160595745 21.53445 0.160595745 21.53445 24 0 24"/>
      <polygon id="path-3" points="0 34 140 34 140 0 0 0"/>
    </defs>
    <g id="Homepage-(+-coming-soon)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Homepagina-light" transform="translate(-61.000000, -52.000000)">
        <g id="header" transform="translate(61.000000, 52.000000)">
          <g id="logo-svg">
            <g id="Group-3" transform="translate(0.000000, 10.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"/>
              </mask>
              <g id="Clip-2"/>
              <path
                d="M21.53445,13.4954043 C21.53445,20.8342979 16.46195,24.0002553 10.77895,24.0002553 C5.07245,24.0002553 -5e-05,20.8342979 -5e-05,13.4954043 L-5e-05,0.160595745 L6.12945,0.160595745 L6.12945,12.9439149 C6.12945,16.6608511 8.12545,18.1723404 10.77895,18.1723404 C13.40945,18.1723404 15.42895,16.6608511 15.42895,12.9439149 L15.42895,0.160595745 L21.53445,0.160595745 L21.53445,13.4954043 Z"
                id="Fill-1" fill={color} mask="url(#mask-2)"/>
            </g>
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3"/>
            </mask>
            <g id="Clip-5"/>
            <polygon id="Fill-4" fill={color} mask="url(#mask-4)" points="53 33 59 33 59 10 53 10"/>
            <polygon id="Fill-6" fill={color} mask="url(#mask-4)"
                     points="72.0504813 18.7000976 74.183303 18.7000976 80.5121716 10.7936036 87.9304032 10.7936036 79.0748244 21.2437012 88 33 80.5585695 33 74.0910009 24.0166341 72.0504813 24.0166341 72.0504813 33 66 33 66 0 72.0504813 0"/>
            <path
              d="M95.0914225,21.9997495 C95.0914225,25.6939069 97.6107838,28.2824222 100.977083,28.2824222 C104.342895,28.2824222 106.885173,25.6939069 106.885173,21.9997495 C106.885173,18.3055921 104.342895,15.7175778 100.977083,15.7175778 C97.6107838,15.7175778 95.0914225,18.3055921 95.0914225,21.9997495 M113,21.9997495 C113,28.8700607 107.893015,34 100.977083,34 C94.0835805,34 89,28.8700607 89,21.9997495 C89,15.1294383 94.0835805,10 100.977083,10 C107.893015,10 113,15.1294383 113,21.9997495"
              id="Fill-7" fill={color} mask="url(#mask-4)"/>
            <path
              d="M122.091423,21.9997495 C122.091423,25.6939069 124.610784,28.2824222 127.977083,28.2824222 C131.342895,28.2824222 133.885173,25.6939069 133.885173,21.9997495 C133.885173,18.3055921 131.342895,15.7175778 127.977083,15.7175778 C124.610784,15.7175778 122.091423,18.3055921 122.091423,21.9997495 M140,21.9997495 C140,28.8700607 134.893015,34 127.977083,34 C121.083581,34 116,28.8700607 116,21.9997495 C116,15.1294383 121.083581,10 127.977083,10 C134.893015,10 140,15.1294383 140,21.9997495"
              id="Fill-8" fill={color} mask="url(#mask-4)"/>
            <path
              d="M26,20.1348584 C26,13.0544489 30.9470849,10 36.4885416,10 C42.0534027,10 47,13.0544489 47,20.1348584 L47,33 L41.0231257,33 L41.0231257,20.6669237 C41.0231257,17.0809022 39.0766677,15.6226492 36.4885416,15.6226492 C33.9233323,15.6226492 31.9539576,17.0809022 31.9539576,20.6669237 L31.9539576,33 L26,33 L26,20.1348584 Z"
              id="Fill-9" fill={color} mask="url(#mask-4)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>);
