import { FC } from 'react';
import styles from './LanguageSwitcher.module.scss';
import { useRouter } from 'next/router';
import { Link } from 'components/Link';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { Arrow } from 'components/Icons';
import { LanguageSwitcherProps as Props } from './types';
import { languages } from 'consts/common.consts';
import { setLocaleCookie } from 'utils/common';

export const LanguageSwitcher: FC<Props> = ({ type, localized, className }) => {
  const { locale, route } = useRouter();

  const links = [{ language: locale, uri: route }, ...localized].map((loc) => (
    <Link
      href={`/${loc.language}/${loc.uri === '__home__' ? '' : loc.uri}`}
      key={loc.language}
      className={`${locale === loc.language ? styles.active : ''}`}
      onClick={() => setLocaleCookie(loc.language)}
    >
      {loc.language?.toUpperCase()}
    </Link>
  ));
  const current = languages.find((lang) => lang.locale === locale);

  return (
    <div
      className={`${styles.languageSwitcher} ${styles[type]} ${
        className ?? ''
      }`}
    >
      {type === 'header' ? (
        <Dropdown
          minWidth={false}
          label={
            <h2>
              {current.label}
              <Arrow />
            </h2>
          }
          entries={links}
        />
      ) : (
        links
      )}
    </div>
  );
};
