import React, { HTMLProps } from 'react';
import styles from './Badge.module.scss';

type BadgeProps = {
  amount: Number;
} & HTMLProps<HTMLDivElement>;

export const Badge = ({ amount, className }: BadgeProps) => (
  <div className={`${styles.badgeWrapper} ${className ?? ''} `}>
    <p className={`${styles.badgeAmount}`}>{amount}</p>
  </div>
);
